import Swal from 'sweetalert2';

const initSweetalert = (selector, options = {}) => {
  const swalButton = document.querySelector(selector);
  if (swalButton) { // protect other pages
    swalButton.addEventListener('click', () => {
      setTimeout(() => {
        Swal.fire(options);
      }, 1500)
    });
  }
};

export { initSweetalert };