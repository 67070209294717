import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "common", "cpf", "opco", "faf", "submit", "dpcType", "extraitD1", "activityType"]
  connect() {
    const budgetType = this.dpcTypeTarget.querySelector("option[selected='selected']");
    if (budgetType) {
      this.selectForm(budgetType.innerText);
      this.displayExtraitD1();
    }
  }

  displayForm(e) {
    this.selectForm(e.currentTarget.value)
  }

  selectForm(el) {
    this.commonTarget.classList.remove("d-none");
    this.submitTarget.classList.remove("d-none");
    if (el === "CPF") {
      this.cpfTarget.classList.remove("d-none");
      this.opcoTarget.classList.add("d-none");
      this.fafTarget.classList.add("d-none");
    }
    if (el === "OPCO") {
      this.opcoTarget.classList.remove("d-none");
      this.cpfTarget.classList.add("d-none");
      this.fafTarget.classList.add("d-none");
    }
    if (el === "FAF") {
      this.fafTarget.classList.remove("d-none");
      this.opcoTarget.classList.add("d-none");
      this.cpfTarget.classList.add("d-none");
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.element.querySelectorAll(".d-none").forEach(el => {
      el.remove();
    });
    this.element.submit();
  }

  displayExtraitD1() {
    this.extraitD1Target.classList.add("d-none");
    if (this.activityTypeTarget.value === "Artisan") {
      this.extraitD1Target.classList.remove("d-none");
    }
  }
}
